import React from 'react'
import { Link } from 'gatsby'
import { LayoutWithImage } from '../../layouts'
import Seo from '../../components/seo'
import { CONTENT } from '../../content'
import BreadcrumbList from '../../components/BreadcrumbList/BreadcrumbList'
import BreadcrumbItem from '../../components/BreadcrumbList/BreadcrumbItem'

const Meeting = () => (
  <LayoutWithImage>
    <Seo
      title={CONTENT.MEETING.GENERAL.title}
      description={CONTENT.MEETING.GENERAL.content[0]}
    />

    <section>
      <header>
        <h1>{CONTENT.MEETING.GENERAL.title}</h1>
      </header>

      <div>
        {CONTENT.MEETING.GENERAL.content.map((content, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
        ))}
      </div>
    </section>

    <section style={{ marginTop: '4rem' }}>
      <header>
        <h2>{CONTENT.MEETING.CHILDREN.title}</h2>
      </header>

      <div>
        {CONTENT.MEETING.CHILDREN.content.map((content, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
        ))}
      </div>
    </section>

    <BreadcrumbList>
      <>
        <BreadcrumbItem position={1}>
          <Link className="link" itemProp="item" to="/">
            <span itemProp="name">Accueil</span>
          </Link>
        </BreadcrumbItem>
        ›
        <BreadcrumbItem position={2}>
          <Link className="link" itemProp="item" to="/consultation">
            <span itemProp="name">Consultation</span>
          </Link>
        </BreadcrumbItem>
        ›
        <BreadcrumbItem position={3}>
          <span itemProp="name">Déroulement d'une séance</span>
        </BreadcrumbItem>
      </>
    </BreadcrumbList>
  </LayoutWithImage>
)

export default Meeting
